<template>
  <div>
    <div v-if="isMobile===false">
      <div class="Login"  v-if="languageSelect==='zh'">
        <div class="header">
          <div class="centerWidth">
<!--            <router-link to="/" class="login">-->
<!--              <img loading="lazy" src="../assets/talentlogo.png" alt="" />-->
<!--            </router-link>-->
            <div class="name">账号登录</div>
          </div>
        </div>
        <div class="center">
          <div class="centerWidth">
<!--           <div class="left" style="height: 70vh;">-->
<!--             <img loading="lazy" src="../assets/joblogo.png" alt="" />-->
<!--           </div>-->
            <div class="right">
              <div class="tabsBar">
                <div
                    class="barItem"
                    @click="barChange()"
                    :class="isbarActive == 1 ? 'activebarCls' : ''"
                >
                  我要找工作
                </div>
                <div
                    class="barItem"
                    @click="barChange()"
                    :class="isbarActive == 2 ? 'activebarCls' : ''"
                >我要招聘
                </div>
              </div>
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  账号登录
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  短信登录
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入邮箱/手机号"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="请输入密码"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入手机号"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="请输入验证码"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCode()"
                    >{{ setCode }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submit()">登录</div>
                <div class="flex">
                  <router-link to="/register" class="register">
                    没有账号？<span>立即注册</span>
                  </router-link>
                  <router-link to="/forgetPassword" class="forgetPassword">
                    忘记密码>>
                  </router-link>
                </div>
                <div class="weixin" @click="wechat()">
                  <span>使用社交账号登录</span>
                  <img loading="lazy" src="../assets/wechart.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
      <div class="Login"  v-if="languageSelect==='en'">
        <div class="header">
          <div class="centerWidth">
<!--            <router-link to="/" class="login">-->
<!--              <img loading="lazy" src="../assets/logo.png" alt="" />-->
<!--            </router-link>-->
            <div class="name">Account login</div>
          </div>
        </div>
        <div class="center">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  Account login
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  SMS login
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="Please enter your email/phone number"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="Please enter password"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="Please enter your phone number"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="Please enter the verification code"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCodeEn()"
                    >{{ setCodeEn }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submitEn()">Log in</div>
                <div class="flex">
                  <router-link to="/register" class="register">
                    No account？<span>Register now</span>
                  </router-link>
                  <router-link to="/forgetPassword" class="forgetPassword">
                    Forgot password>>
                  </router-link>
                </div>
                <div class="weixin" @click="wechat()">
                  <span>Sign in using social accounts</span>
                  <img loading="lazy" src="../assets/wechart.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
    </div>
    <div v-if="isMobile===true">
      <div class="Login1"  v-if="languageSelect==='zh'">
        <div class="header1">
          <div class="centerWidth">
<!--            <router-link to="/" class="login">-->
<!--              <img loading="lazy" src="../assets/logo.png" alt="" />-->
<!--            </router-link>-->
            <div class="name">账号登录</div>
          </div>
        </div>
        <div class="center1">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  账号登录
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  短信登录
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入邮箱/手机号"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="请输入密码"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入手机号"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="请输入验证码"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCode()"
                    >{{ setCode }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submit()">登录</div>
                <div class="flex">
                  <router-link to="/register" class="register">
                    没有账号？<span>立即注册</span>
                  </router-link>
                  <router-link to="/forgetPassword" class="forgetPassword">
                    忘记密码
                  </router-link>
                </div>
<!--                <div class="weixin" @click="wechat()">-->
<!--                  <span>使用社交账号登录</span>-->
<!--                  <img loading="lazy" src="../assets/wechart.png" alt="" />-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
      <div class="Login1"  v-if="languageSelect==='en'">
        <div class="header1">
          <div class="centerWidth">
<!--            <router-link to="/" class="login">-->
<!--              <img loading="lazy" src="../assets/logo.png" alt="" />-->
<!--            </router-link>-->
            <div class="name">Account login</div>
          </div>
        </div>
        <div class="center1">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  Account login
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  SMS login
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="Please enter your email/phone number"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="Please enter password"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="Please enter your phone number"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="Please enter the verification code"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCodeEn()"
                    >{{ setCodeEn }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submitEn()">Log in</div>
                <div class="flex">
                  <router-link to="/register" class="register">
                    No account？<span>Register now</span>
                  </router-link>
                  <router-link to="/forgetPassword" class="forgetPassword">
                    Forgot password>>
                  </router-link>
                </div>
<!--                <div class="weixin" @click="wechat()">-->
<!--                  <span>Sign in using social accounts</span>-->
<!--                  <img loading="lazy" src="../assets/wechart.png" alt="" />-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
    </div>
  </div>

</template>

<script>
import SimpleBottom from "@/components/SimpleBottom.vue";
import { phoneCode, userLogin, phoneLogin, getWxCode } from "@/api/user";
export default {
  name: "Login",
  components: { SimpleBottom },
  data() {
    return {
      Bar:"我要找工作",
      isMobile:false,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      isActive: 1,
      isbarActive:1,
      disabled: false,
      accountLogin: {
        username: "",
        password: "",
      },
      phoneLogin: {
        phone: "",
        code: "",
      },
      setCode: "发送验证码",
      setCodeEn: "Verification code",
    };
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
  },
  methods: {
    tabsChange() {
      this.isActive = this.isActive == 1 ? 2 : 1;
    },
    barChange(){
      this.isbarActive=this.isbarActive==1?2:1
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      if (!this.phoneLogin.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      const reg = /^\d{6,13}$/;
      if (!reg.test(this.phoneLogin.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      let data = {
        phone: this.phoneLogin.phone,
        event:'login',
      };
      phoneCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtn(); //验证码倒数60秒
      });
    },
    getCodeEn() {
      if (!this.phoneLogin.phone) {
        this.$message.error("Please enter your phone number");
        return false;
      }
      const reg = /^\d{6,13}$/;
      if (!reg.test(this.phoneLogin.phone)) {
        this.$message.error("Please enter the correct phone number");
        return false;
      }
      let data = {
        phone: this.phoneLogin.phone,
        event:'login',
      };
      phoneCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtnEn(); //验证码倒数60秒
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Get verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn =  "Try again in "+time +" seconds";
          time--;
        }
      }, 1000);
    },
    submit() {
      if (this.isActive == 1) {
        if (!this.accountLogin.username) {
          this.$message.error("请输入邮箱/手机号");
          return false;
        }
        if (!this.accountLogin.password) {
          this.$message.error("请输入密码");
          return false;
        }
        this.$axios.post(
                "/system/job_user/login",
                {
                  username: this.accountLogin.username,
                  password: this.accountLogin.password,
                }).then((res) => {
          // console.log("---------------")
          // console.log(res.data)
          localStorage.setItem("access_token", res.data.data.token);
          this.$store.state.access_token = res.data.data.token;
          localStorage.setItem("typeInfo", res.data.data.type);
          this.$store.state.typeInfo = res.data.data.type;
          localStorage.setItem("userId", res.data.data.userId);
          this.$store.state.userId = res.data.data.userId;
          this.$router.go(-1);
          this.$message({
            message: "登录成功！", //提示的信息
            type: "success", //类型是成功
          });
        }).catch(err=>{
          console.log(err)
        });
      } else {
        if (!this.phoneLogin.phone) {
          this.$message.error("请输入手机号");
          return false;
        }
        const reg = /^\d{6,13}$/;
        if (!reg.test(this.phoneLogin.phone)) {
          this.$message.error("请输入正确的手机号");
          return false;
        }
        if (!this.phoneLogin.code) {
          this.$message.error("请输入验证码");
          return false;
        }
        phoneLogin(this.phoneLogin).then((res) => {
          localStorage.setItem("access_token", res.data.data.token);
          this.$store.state.access_token = res.data.data.token;
          localStorage.setItem("typeInfo", res.data.data.type);
          this.$store.state.typeInfo = res.data.data.type;
          localStorage.setItem("userId", res.data.data.userId);
          this.$store.state.userId = res.data.data.userId;
          // let type = res.data.data.type;
          this.$message({
            message: "登录成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              // if (type == 0) {
              //   this.$router.push({ name: "BasicInformation" });
              // } else {
              this.$router.go(-1);
              // }
            },
          });
        }).catch(err=>{
          console.log(err)
        });
      }
    },
    submitEn() {
      if (this.isActive == 1) {
        if (!this.accountLogin.username) {
          this.$message.error("Please enter your email/phone number");
          return false;
        }
        if (!this.accountLogin.password) {
          this.$message.error("Please enter password");
          return false;
        }
        userLogin(this.accountLogin).then((res) => {

          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("typeInfo", res.data.type);
          this.$store.state.typeInfo = res.data.type;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          // let type = res.data.type;
          this.$message({
            message: "Login successful！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              // if (type == 0) {
              //   this.$router.push({ name: "BasicInformation" });
              // } else {
              this.$router.go(-1);
              // }
            },
          });
        }).catch(error=>{
          this.$message({
            message: "The user does not exist or the password is incorrect！",
            type: "error",
            duration: 1200,
          })
          console.log(error)
        })

        ;
      } else {
        if (!this.phoneLogin.phone) {
          this.$message.error("Please enter your phone number.");
          return false;
        }
        const reg = /^\d{6,13}$/;
        if (!reg.test(this.phoneLogin.phone)) {
          this.$message.error("Please enter the correct phone number");
          return false;
        }
        if (!this.phoneLogin.code) {
          this.$message.error("Please enter the verification code");
          return false;
        }
        phoneLogin(this.phoneLogin).then((res) => {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("typeInfo", res.data.type);
          this.$store.state.typeInfo = res.data.type;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          // let type = res.data.type;
          this.$message({
            message: "Login successful！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              // if (type == 0) {
              //   this.$router.push({ name: "BasicInformation" });
              // } else {
              this.$router.go(-1);
              // }
            },
          });
        });
      }
    },
    wechat() {
      // let url = window.location.href;
        let url = this.baseURL + "/success";
      getWxCode(url).then((res) => {
        console.log(res, "res");
        window.location.href = res.data.url
      });
    },
  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
};
</script>
<style lang="scss" scoped>

.header {
  background: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  .centerWidth {
    display: flex;
    align-items: center;
    .login {
      // width: 168px;
      height: 40px;
      background: url(../assets/login.png) left top no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .name {
      margin-left: 25px;
      padding-left: 30px;
      font-size: 20px;
      color: var(--main-color);
      height: 22px;
      line-height: 22px;
      border-left: 1px solid var(--main-color);
    }
  }
}
.center {
  height: calc(100vh - 46px - 72px);
  background-size: 100% 100%;
  background-color: #fde6eb;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .centerWidth {
    display: flex;
    align-items: center;
    justify-content: center;

    .right {
      height: 70vh;
      width: 510px;
      background: #fff;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding: 42px 33px;
      cursor: pointer;
      .tabsBar{
        height: 50px;
        width: 100%;
        text-align: center;
        padding: 5px;
        margin-bottom: 25px;
        background-color: #f6f6f6;
        display: flex;
        justify-content: center;
        align-items: center;
        .barItem{
          width: 48%;
          padding: 8px;
          border-radius: 5px;
        }
        .activebarCls{
          width: 49%;
          background-color: white;
          padding: 8px;
          border-radius: 5px;
          color: var(--main-color);
        }
      }

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eeeeee;

        .tabsItem {
          // height: 29px;
          // line-height: 29px;
          margin: 0 20px;
          padding-bottom: 12px;
          font-size: 18px;
          color: #bbbbbb;
          cursor: pointer;
        }
        .tabsItem.activeCls {
          font-size: 20px;
          color: #333333;
          position: relative;
        }

        .tabsItem.activeCls:after {
          content: "";
          width: 100%;
          height: 2px;
          background: var(--main-color);
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
      .tabContain {
        width: 100%;
        padding-top: 20px;
        .items {
          display: none;
          > div {
            margin-top: 20px;
          }
          ::v-deep {
            .el-input__inner {
              // height: 100%;
              line-height: 50px;
            }
            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              border-color: #dcdfe6;
            }
          }
          .el-input {
            width: 100%;
            height: 50px;
            line-height: 50px;
            border: 1px solid #d0d0d0;
            border-radius: 4px;
          }
          .el-input:place-holder {
            color: #bbbbbb;
          }
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ::v-deep {
              .setCode {
                width: 120px;
                height: 50px;
                // line-height: 50px;
                text-align: center;
                border-radius: 4px;
                background: var(--main-color);
                color: #fff;
                font-size: 14px;
                margin-left: 15px;
              }
            }

            .el-input {
              flex: 1;
            }
          }
        }

        .items.activeCls {
          display: block;
        }

        .submit {
          margin-top: 40px;
          height: 50px;
          line-height: 50px;
          border-radius: 4px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 18px;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          a.forgetPassword,
          .register span {
            color: var(--main-color);
          }
        }
        .weixin {
          margin-top: 20px;
          text-align: left;
          display: flex;
          align-items: center;
          color: #555555;
          font-size: 14px;
          img {
            width: 30px;
            height: 30px;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.header1 {
  background: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  .centerWidth {
    display: flex;
    align-items: center;
    .login {
       width: 168px;
      height: auto;
    }
    .name {
      margin-left: 25px;
      padding-left: 30px;
      font-size: 20px;
      color: var(--main-color);
      height: 22px;
      line-height: 22px;
      border-left: 1px solid var(--main-color);
    }
  }
}
.center1 {
  height:75vh;
  background: url(../assets/login.png) left top no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;

  .centerWidth {
    display: flex;
    align-items: center;

    .right {
      width: 80%;
      margin: auto;
      align-items: center;
      text-align: center;
      height: fit-content;
      background: #fff;
      border-radius: 5px;
      padding: 12px 13px;

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eeeeee;

        .tabsItem {
          // height: 29px;
          // line-height: 29px;
          margin: 0 10px;
          padding-bottom: 12px;
          font-size: 16px;
          color: #bbbbbb;
          cursor: pointer;
        }
        .tabsItem.activeCls {
          font-size: 16px;
          color: #333333;
          position: relative;
        }

        .tabsItem.activeCls:after {
          content: "";
          width: 100%;
          height: 2px;
          background: var(--main-color);
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
      .tabContain {
        width: 100%;
        padding-top: 10px;
        .items {
          display: none;
          > div {
            margin-top: 10px;
          }
          .el-input {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border: 1px solid #d0d0d0;
            border-radius: 4px;
            font-size: 14px;
          }
          ::v-deep {
            .el-input__inner {
              line-height: 35px;
              height: 35px;
            }
            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              border-color: #dcdfe6;
            }
          }
          .el-input:place-holder {
            color: #bbbbbb;
          }
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ::v-deep {
              .setCode {
                width: 75px;
                height: 40px;
                // line-height: 50px;
                text-align: center;
                border-radius: 4px;
                background: var(--main-color);
                color: #fff;
                font-size: 14px;
                margin-left: 5px;
              }
            }

            .el-input {
              flex: 1;
            }
          }
        }

        .items.activeCls {
          display: block;
        }

        .submit {
          margin-top: 30px;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 14px;
        }
        .flex {
          justify-content: space-between;
          margin-top: 10px;
          font-size: 14px;
          line-height: 30px;
          color: #333333;
          a.forgetPassword,
          .register span {
            color: var(--main-color);
          }
        }
        .weixin {
          margin-top: 10px;
          text-align:center;
          align-items: center;
          color: #555555;
          font-size: 14px;
          img {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
::v-deep {
  .el-input__inner {
    // height: 100%;
    line-height: 50px;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #dcdfe6;
  }
}
input {
  border: none;
  outline: none;
}
</style>
